export const isIOSOrSafari: () => boolean = () => {
  // https://stackoverflow.com/q/9038625/3569421
  if ((navigator.userAgent.indexOf("Chrome") <= 0 && navigator.userAgent.indexOf("Safari") >= 0) ||
    (navigator.userAgent.indexOf("Mac") >= 0 && ("ontouchend" in document)))
    return true;
  switch (navigator.platform) {
    case "iPad Simulator":
    case "iPhone Simulator":
    case "iPod Simulator":
    case "iPad":
    case "iPhone":
    case "iPod":
      return true;
  }
  return false;
}

export const windowSize: () => { width: number, height: number } = () => {
  let width = window.innerWidth,
    height = window.innerHeight;

  if (document.documentElement && ("clientWidth" in document.documentElement)) {
    width = document.documentElement.clientWidth;
    height = document.documentElement.clientHeight;
  }

  if (isIOSOrSafari()) {
    const bodyRect = (document.documentElement && ("getBoundingClientRect" in document.documentElement)) ?
      document.documentElement.getBoundingClientRect() :
      (document.body && ("getBoundingClientRect" in document.body)) ?
        document.body.getBoundingClientRect() :
        null;

    if (bodyRect) {
      width = bodyRect.right - bodyRect.left;
      height = bodyRect.bottom - bodyRect.top;
    }
  }

  return { width, height };
}