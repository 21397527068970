import { Deck } from "./Deck";
import { Coil } from "./components/Coil";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Slide } from "./components/Slide";
import { useEffect } from "react";

const deckConfigurations = [
  {
    path: "ai-grant",
    names: "dan + grant",
  },
  {
    path: "ruby",
    names: "shariar + miguel",
  }
]


function App() {
  useEffect(() => {
    const resize = () => {
      const canvas = document.getElementById("canvas") as HTMLCanvasElement;
      if (canvas === null) return;

      const intro = document.getElementById("coil") as HTMLDivElement;
      if (intro === null) return;
      intro.style.minHeight = window.innerHeight + "px";

      window.document
        .querySelectorAll<HTMLElement>(".slide")
        .forEach((slide) => {
          slide.style.opacity = "1";
        });
    };
    resize();
    window.addEventListener("resize", resize);
  }, []);
  
  return (
    <>
      <Coil/>
      <Router>
        <Routes>   
          <Route path="/" element={<Slide id="coil">coil</Slide>} />
          <Route path="/ai-grant" element={<Deck names="dan + nat"/>} />
          <Route path="/alice" element={<Deck names="alice"/>} />
          <Route path="/george" element={<Deck names="george"/>} />
          <Route path="/brian" element={<Deck names="brian"/>} />
          <Route path="/colon" element={<Deck names="nancy + james"/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
