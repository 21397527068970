import { useEffect } from "react";
import { Slide } from "./components/Slide";

const useScrollFadeEffect = () => {
  const fadeThreshold = 20; // Percentage of the viewport height where fading occurs
  const maxBlur = 4; // Maximum blur value in pixels

  useEffect(() => {
    const handleScroll = () => {
      const viewportHeight = window.innerHeight;
      const fadeLines = document.querySelectorAll(".fade-line");
      const fadeMargin = (viewportHeight * fadeThreshold) / 100; // Calculate fade margin based on threshold

      fadeLines.forEach((line: Element) => {
        const rect = line.getBoundingClientRect();
        // Determine how far the element is from the edge of the viewport
        const distanceToTopEdge = rect.top;
        const distanceToBottomEdge = viewportHeight - rect.bottom;
        let opacity = 1;
        let blur = 0; // Default blur value

        // Check if the element is within the top fade margin
        if (distanceToTopEdge < fadeMargin) {
          opacity = distanceToTopEdge / fadeMargin;
          blur = maxBlur * (1 - opacity); // Increase blur as the element approaches the viewport edge
        }
        // Check if the element is within the bottom fade margin
        else if (distanceToBottomEdge < fadeMargin) {
          opacity = distanceToBottomEdge / fadeMargin;
          blur = maxBlur * (1 - opacity); // Increase blur as the element approaches the viewport edge
        }

        (line as HTMLElement).style.opacity = `${opacity}`;
        (line as HTMLElement).style.filter = `blur(${blur}px)`;
      });
    };

    // Call it once to apply the effect on load
    handleScroll();

    window.addEventListener("scroll", handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [fadeThreshold]); // Add fadeThreshold as a dependency if you plan to make it dynamic
};

export const Deck: React.FC<{ names: string }> = ({ names }) => {
  useScrollFadeEffect();

  return (
    <>
      <Slide id="coil">
        <span className="fade-line">coil</span>
      </Slide>
      <Slide id="description">
        <span className="fade-line">personal</span>
        <br />
        <span className="fade-line">interactive</span>
        <br />
        <span className="fade-line">experiences</span>
      </Slide>
      <Slide id="note">
        <span className="fade-line">{names}</span>
        <br />
        <br />
        <br />
        <span className="fade-line">thanks for</span>
        <br />
        <span className="fade-line">"getting it"</span>
        <br />
        <span className="fade-line">way back then</span>
        <br />
        <br />
        <br />
        <span className="fade-line">eleazar</span>
        <br />
        <span className="fade-line">
          <span className="opacity-30">(ceo, 2034)</span>
        </span>
      </Slide>
      <Slide id="terminator">
        <span className="fade-line">it happened</span>
        <br />
        <span className="fade-line">so quickly</span>
        <br />
        <br />
        <br />
        <br />
        <span className="fade-line">ai</span>
        <br />
        <span className="fade-line">reshaped</span>
        <br />
        <span className="fade-line">all content</span>
      </Slide>
      <Slide id="media">
        <span className="fade-line">media</span>
        <br />
        <span className="fade-line">was</span>
        <br />
        <br />
        <span className="fade-line">costly</span>
        <br />
        <span className="fade-line">linear</span>
        <br />
        <span className="fade-line">generic</span>
        <br />
        <br />
        <br />
        <br />
        <br />
        <span className="fade-line">we</span>
        <br />
        <span className="fade-line">made it</span>
        <br />
        <br />
        <span className="fade-line">infinite</span>
        <br />
        <span className="fade-line">interactive</span>
        <br />
        <span className="fade-line">personal</span>
      </Slide>
      <Slide id="competition">
        <span className="fade-line">costs</span>
        <br />
        <span className="fade-line">went to zero</span>
        <br />
        <br />
        <span className="fade-line">everyone</span>
        <br />
        <span className="fade-line">copied us</span>
        <br />
        <br />
        <br />
        <br />
        <br />
        <span className="fade-line">we won</span>
        <br />
        <span className="fade-line">with</span>
        <br />
        <br />
        <span className="fade-line">craft</span>
        <br />
        <span className="fade-line">tech</span>
        <br />
        <span className="fade-line">soul</span>
      </Slide>
      <Slide id="team">
        <span className="fade-line">we had the</span>
        <br />
        <span className="fade-line">perfect team</span>
        <br />
        <br />
        <br />
        <br />
        <br />
        <span className="fade-line">me</span> <br />
        <span className="fade-line">+</span>
        <br />
        <span className="fade-line">chatgpt</span>
        <br />
        <span className="fade-line">+</span>
        <br />
        <span className="fade-line">copilot</span>
        <br />
        <br />
        <span className="fade-line">
          <span className="opacity-25">(before magic)</span>
        </span>
      </Slide>
      <Slide id="founder">
        <span className="fade-line">my time</span>
        <br />
        <br />
        <br />
        <span className="fade-line">programming</span>
        <br />
        <span className="fade-line">ai chips</span>
        <br />
        <span className="fade-line">
          <span className="opacity-25">(rem s15)</span>
        </span>
        <br />
        <br />
        <span className="fade-line">pivoting</span>
        <br />
        <span className="fade-line">
          <span className="opacity-25">(coil w23)</span>
        </span>
        <br />
        <br />
        <span className="fade-line">obsessing</span>
        <br />
        <br />
        <br />
        <span className="fade-line">
          <i>finally</i>
        </span>
        <br />
        <span className="fade-line">paid off</span>
      </Slide>
      <Slide id="demos">
        <span className="fade-line">remember those</span>
        <br />
        <span className="fade-line">early demos?</span>
        <br />
        <br />
        <br />
        <ol>
          <li>
            <a
              href="https://thedemosthatstarteditall.com/adventure"
              className="block"
            >
              <span className="fade-line">1</span>
              <br />
              <span className="fade-line">
                <span className="opacity-25">(games)</span>
              </span>
            </a>
          </li>

          <br />
          <li>
            <a
              href="https://thedemosthatstarteditall.com/book"
              className="block"
            >
              <span className="fade-line">2</span>
              <br />
              <span className="fade-line">
                <span className="opacity-25">(novels)</span>
              </span>
            </a>
          </li>
          <br />
          <li>
            <a
              href="https://thedemosthatstarteditall.com/kids"
              className="block"
            >
              <span className="fade-line">3</span>
              <br />
              <span className="fade-line">
                <span className="opacity-25">(kids)</span>
              </span>
            </a>
          </li>
        </ol>
        <br />
        <br />
        <span className="fade-line">amazing</span>
        <br />
        <span className="fade-line">the links</span>
        <br />
        <span className="fade-line">still work</span>
        <br />
        <br />
        <br />
      </Slide>
      <Slide id="exciting">
        <span className="fade-line">i built 2 + 3</span>
        <br />
        <span className="fade-line">in like 2 days</span>
        <br />
        <br />
        <br />
        <span className="fade-line">i was barely</span>
        <br />
        <span className="fade-line">sleeping</span>
        <br />
        <br />
        <br />
        <span className="fade-line">but it was</span>
        <br />
        <span className="fade-line">so exciting</span>
      </Slide>
      <Slide id="moat">
        <span className="fade-line">i</span>
        <br />
        <span className="fade-line">was our</span>
        <br />
        <span className="fade-line">only moat</span>
        <br />
        <br />
        <br />
        <br />
        <span className="fade-line">so i used</span>
        <br />
        <span className="fade-line">nostalgia</span>
        <br />
        <br />
        <span className="fade-line">to build</span>
        <br />
        <span className="fade-line">audiences</span>
        <br />
        <br />
        <span className="fade-line">to get</span>
        <br />
        <span className="fade-line">capital</span>
        <br />
        <br />
        <span className="fade-line">to invest</span>
        <br />
        <span className="fade-line">
          in <span className="font-bold glow">the engine</span>
        </span>
      </Slide>
      <Slide id="crazy">
        <span className="fade-line">
          it was <i>just</i>
        </span>
        <br />
        <span className="fade-line italic">crazy enough</span>
        <br />
        <span className="fade-line">to work</span>
      </Slide>
      <Slide id="thanks">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <span className="fade-line">thanks</span>
        <br />
        <span className="fade-line">again</span>
        <br />
        <br />
        <br />
        <span className="fade-line">i'll see you</span>
        <br />
        <span className="fade-line">at the meeting</span>
        <br />
        <br />
        <br />
        <br />
        <span className="fade-line">it's on tattoine</span>
      </Slide>
      <Slide id="one-more-thing">
        {/* <span className="fade-line">one more thing...</span> */}
        <span className="fade-line">o</span>
        <br />
        <span className="fade-line">n</span>
        <br />
        <span className="fade-line">e</span>
        <br />
        <br />
        <span className="fade-line">m</span>
        <br />
        <span className="fade-line">o</span>
        <br />
        <span className="fade-line">r</span>
        <br />
        <span className="fade-line">e</span>
        <br />
        <br />
        <span className="fade-line">t</span>
        <br />
        <span className="fade-line">h</span>
        <br />
        <span className="fade-line">i</span>
        <br />
        <span className="fade-line">n</span>
        <br />
        <span className="fade-line">g</span>
        <br />
        <span className="fade-line">.</span>
        <br />
        <span className="fade-line">.</span>
        <br />
        <span className="fade-line">.</span>
        <br />
      </Slide>
      <Slide id="unicorns">
        <span className="fade-line">rememeber</span>
        <br />
        <span className="fade-line">"unicorns"?</span>
        <br />
        <br />
        <span className="fade-line">we used to</span>
        <br />
        <span className="fade-line">
          <i className="opacity-75">dream</i>
        </span>

        <br />
        <span className="fade-line">
          <i className="text-sm opacity-50">so</i>
        </span>
        <br />
        <span className="fade-line">
          <i className="text-xs opacity-25">small</i>
        </span>
      </Slide>
    </>
  );
};
