import { useEffect, useRef } from "react";
import { windowSize } from "../screenSize";

interface CoilParameters {
  numberOfCircles?: number;
  dotsPerCircle?: number;
  rotationSpeed?: number;
}

interface PolarCoordinate {
  radius: number;
  angle: number;
}

export const Coil: React.FC<CoilParameters> = ({
  numberOfCircles = 200,
  dotsPerCircle = 20,
  rotationSpeed = 0.001,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const initialized = useRef(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas === null) return;

    const ctx = canvas.getContext("2d");
    if (ctx === null) return;

    if (initialized.current) return;
    initialized.current = true;

    let width: number,
      height: number,
      cx: number,
      cy: number,
      maxRadius: number;
    let rotation = 0;
    let time = 0;

    const renderDot = (x: number, y: number, r: number) => {
      ctx.beginPath();
      ctx.arc(x, y, r, 0, Math.PI * 2);
      ctx.fill();
    };

    const xy = (radius: number, angle: number) => {
      const x = cx + radius * Math.cos(angle);
      const y = cy + radius * Math.sin(angle);
      return { x, y };
    };

    const init = () => {
      const { width: w, height: h } = windowSize();
      width = canvas.width = window.innerWidth;
      height = canvas.height = window.innerHeight;
      cx = width / 2;
      cy = height / 2;
      maxRadius = Math.sqrt(cx * cx + cy * cy);
      rotation = 0;
      time = 0;
    };

    window.addEventListener("resize", init);
    init();

    let dots: PolarCoordinate[][] = [];
    for (let i = 0; i < numberOfCircles; i++) {
      dots.push([]);
      for (let j = 0; j < dotsPerCircle; j++) {
        dots[i].push({
          radius: i / numberOfCircles,
          angle: Math.PI * 2 * (j / dotsPerCircle) + (i / numberOfCircles) / 5,
        });
      }
    }

    const render = () => {
      rotation += rotationSpeed;

      ctx.clearRect(0, 0, width, height);
      for (let i = 0; i < numberOfCircles; i++) {
        const scale = (1 - i / numberOfCircles) * (i / numberOfCircles);
        const opacity = 10 * scale * scale * 0.4;
        const dotSize = 10 * scale;
        ctx.fillStyle = `rgba(255, 255, 255, ${opacity})`;

        for (let j = 0; j < dotsPerCircle; j++) {
          const dot = dots[i][j];
          dot.radius += Math.sin((time * i)) / 50000;
          dot.angle += rotationSpeed + (i / numberOfCircles) / 500;

          const { x, y } = xy(dot.radius * maxRadius, dot.angle);

          renderDot(x, y, dotSize);
        }
      }

      time += 0.002;
      setTimeout(render, 1000 / 60);
    };

    render();
  });

  return <canvas ref={canvasRef} className="fixed top-0 left-0" id="canvas"></canvas>;
};
