import { useEffect, useRef } from "react";
import clsx from "clsx";

interface SlideParameters {
  id: string;
  children?: React.ReactNode;
}

export const Slide: React.FC<SlideParameters> = ({ id, children }) => {
  return (
    <div
      id={id}
      className={clsx("slide w-screen flex items-center justify-center p-8 z-10 relative transition-all opacity-0",
      id === "coil" ? "" : "min-h-screen")}
    >
      <div className="text-center">{children}</div>
    </div>
  );
};
